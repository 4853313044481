<template>
  <div
    class="credit-page"
    :style="'--aog-currency:\'' + SYSLANG.currency + '\';'"
  >
    <div class="header-block">
      <van-dropdown-menu
        :close-on-click-outside="false"
        active-color="var(--van-card-price-color)"
      >
        <van-dropdown-item
          v-model="incomeType"
          :options="options"
          @change="onRefresh"
        />
      </van-dropdown-menu>
    </div>
    <van-pull-refresh
      v-model="refreshing"
      @refresh="onRefresh"
      :pulling-text="SYSLANG.下拉即可刷新"
      :loosing-text="SYSLANG.释放即可刷新"
      :loading-text="SYSLANG.loading"
    >
      <van-list
        v-model:loading="isloading"
        :finished="finished"
        :finished-text="SYSLANG.finished"
        :loading-text="SYSLANG.loading"
        @load="getLogs"
      >
        <ul class="logs">
          <template v-for="(log, index) in list" :key="index">
            <li>
              <div class="price">
                <span class="p">{{log.profitMoney/100}}</span>
                <label class="status">{{
                    log.profitName
                }}</label>
              </div>
			  <div class="info">
			    {{ PAGELANG.来源 }}:{{ log.orderCode }}
			    <template v-if="log.OrderUID">
			      <br />
			      {{ ORDERLANG.ordernum }}: {{ log.OrderUID }}
			    </template>
			  </div>
			  <div  class="info">
			    {{ "業績" }}:
			    {{ SYSLANG.currency + formatMoney(log.profitMoney) }}
			  </div>
			  
              <div v-if="incomeType == 1" class="info">
                {{ PAGELANG.受邀人 }}: {{ log.AgentName }} ({{ log.AgentCode }})
              </div>
              <div v-if="incomeType == 2" class="info">
                {{ PAGELANG.来源 }}: {{ log.AgentName }} ({{ log.AgentCode }})
                <template v-if="log.OrderUID">
                  <br />
                  {{ ORDERLANG.ordernum }}: {{ log.OrderUID }}
                </template>
              </div>
              <div v-if="incomeType == 3" class="info">
                {{ PAGELANG.团队业绩 }}:
                {{ SYSLANG.currency + formatMoney(log.TeamAmount) }}
              </div>
              <div class="time">
                {{ PAGELANG.收入时间 }}
                <span>{{ log.profitDate }}</span>
              </div>
            </li>
          </template>
        </ul>
      </van-list>
    </van-pull-refresh>
  </div>

  <div
    class="pc-my-page"
    :style="'--aog-currency:\'' + SYSLANG.currency + '\';'"
  >
    <MyMenu :agentInfo="agentInfo" pagename="income" />
    <div class="main">
      <div class="big-title">{{ PAGELANG.收益明细 }}</div>
      <div class="control">
        <el-select
          v-model="incomeType"
          size="large"
          :popper-append-to-body="false"
          @change="onRefresh"
          style="margin-right: 20px"
        >
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.text"
            :value="item.value"
          />
        </el-select>
      </div>

      <el-table :data="list" class="logs" size="large">
        <el-table-column prop="Amount" :label="RECHLANG.金额" width="160">
          <template #default="scope">
            <span class="price">{{ scope.row.Amount }}</span>
          </template>
        </el-table-column>
        <el-table-column :label="RECHLANG.类型" :width="labelWidth">
          <template #default="">
            <span class="status">{{ PAGELANG.收益类型[incomeType - 1] }}</span>
          </template>
        </el-table-column>
        <el-table-column
          v-if="incomeType == 1"
          :label="PAGELANG.受邀人"
          :width="labelWidth"
        >
          <template #default="scope">
            <span v-if="incomeType == 1"
              >{{ scope.row.AgentName }}<br />({{ scope.row.AgentCode }})</span
            >
            <span v-else>-</span>
          </template>
        </el-table-column>
        <el-table-column
          v-if="incomeType != 3"
          prop="OrderUID"
          :label="PAGELANG.来源"
        >
          <template #default="scope">
            <template v-if="scope.row.AgentName">
              {{ scope.row.AgentName }} ({{ scope.row.AgentCode }})
            </template>
            <span v-else>-</span>
            <template v-if="scope.row.OrderUID">
              <br />
              {{ ORDERLANG.ordernum }}: {{ scope.row.OrderUID }}
            </template>
          </template>
        </el-table-column>
        <el-table-column v-if="incomeType == 3" :label="PAGELANG.团队业绩">
          <template #default="scope">
            {{ SYSLANG.currency + formatMoney(scope.row.TeamAmount) }}
          </template>
        </el-table-column>
        <el-table-column
          prop="AddDTimeFormat"
          :label="PAGELANG.收入时间"
          width="180"
          align="right"
        />
      </el-table>

      <el-pagination
        v-if="pagecount > 1"
        @current-change="changePage"
        layout="prev, pager, next"
        :page-size="pagesize"
        :pager-count="pagecount"
        :total="total"
      />
    </div>
  </div>
</template>

<script>
import { closeToast, showLoadingToast, showToast } from "vant";
import { getCookie,formatDateTime } from "../util";
import MyMenu from "../components/MyMenu.vue";

export default {
  components: {
    MyMenu,
  },
  data() {
    return {
      list: [],
      refreshing: false,
      finished: false,
      isloading: false,
      curpage: 0,
      pagecount: 0,
      starttime: "",
      endtime: "",
      incomeType:0,
      options: [],
      agentInfo: {},
      init: true,
      timer: [],
      total: 0,
      pagesize: 0,
      labelWidth: 120,
    };
  },
  beforeCreate() {
    if (!getCookie("agentlogintoken")) {
      this.$router.replace({
        path: "/login",
      });
      return;
    }
  },
  created() {
    if (!getCookie("agentlogintoken")) {
      return;
    }

    this.SYSLANG = this.LANG.system;
    this.PAGELANG = this.LANG.pages.income;
    this.ORDERLANG = this.LANG.pages.order;
    this.RECHLANG = this.LANG.pages.recharge;

    if (this.langcode != "zh") {
      this.labelWidth = 180;
    }



    // for (let i = 0; i < this.PAGELANG.收益类型.length; i++) {
    //   this.options.push({
    //     text: this.PAGELANG.收益类型[i],
    //     value: i + 1,
    //   });
    // }
	this.options = [
	  { text:"待結算", value: 0 },

	];
	

    this.getMemberInfo();
  },
  methods: {
    formatMoney(money) {
      if (!money) return "0.00";
	  return money/100;
      // let integer = Math.floor(money).toString();
      // let thousands = integer
      //   .split("")
      //   .reverse()
      //   .join("")
      //   .replace(/(\d{3})/g, "$1.")
      //   .replace(/,$/, "");

      // if (thousands.substr(-1, 1) == ".") {
      //   thousands = thousands.substring(0, thousands.length - 1);
      // }

      // return thousands.split("").reverse().join("");
    },
    getMemberInfo() {
      showLoadingToast({
        message: this.SYSLANG.loading,
        duration: 0,
        forbidClick: true,
      });

      this.init = true;
      this.axios
        .get(this.actions.memberMoney)
        .then((res) => {
          closeToast();
         let { code, message, data } = res.data;
		 console.log('code',code)
          if (code == 200) {
            this.agentInfo = data;
            this.init = false;
            this.getLogs();
          } else {
            showToast({
              message:
                code == "NOTLOGIN" ? this.SYSLANG[message] : this.PAGELANG[message],
              forbidClick: true,
              onClose: () => {
                if (code == "NOTLOGIN") {
                  this.$router.replace({
                    path: "/login",
                  });
                }
              },
            });
          }
        })
        .catch((error) => {
          //请求失败，触发catch中的函数 可省略
          closeToast();
          console.log("views.CreditLogs.getMemberInfo.error", error);
          //this.getMemberInfo();
        });
    },
    getLogs() {

      if (this.init) {
        return false;
      }

      if (this.refreshing) {
        this.refreshing = false;
      }
      this.finished = true;
      this.curpage++;
      this.axios
        .get(this.actions.memberProfitList,{params:{
		type: this.incomeType,
		page: this.curpage,
		starttime: this.StartTime,
		endtime: this.EndTime,
		pageSize:50		
		}})
        .then((res) => {
          closeToast();
          let { code, message} = res.data;
          let { page, list, total,pageTotal, pageSize } = res.data.data;
          if (code==200) {
            this.curpage = page * 1;
            this.pagecount=pageTotal;
            this.total = total * 1;
            this.pagesize = pageSize * 1;
			list.map((item)=>{
				  item.profitDate=formatDateTime(item.profitDate)
			})
            this.list = this.list.concat(list);
          } else {
            showToast({
              message:
                code == "NOTLOGIN" ? this.SYSLANG[message] : this.PAGELANG[message],
              forbidClick: true,
              onClose: () => {
                if (code == "NOTLOGIN") {
                  this.$router.replace({
                    path: "/login",
                  });
                }
              },
            });
          }

          this.isloading = false;
          this.finished = false;
          if (this.curpage >= this.pagecount) {
            this.finished = true;
          }
        })
        .catch((error) => {
          //请求失败，触发catch中的函数 可省略
          closeToast();
          console.log("views.CreditLogs.getCreditLogs.error", error);
          this.curpage--;
          //this.getCreditLogs();
        });

    },
    // 下拉刷新数据
    onRefresh() {
      this.list = [];
      this.isloading = false;
      this.finished = false;
      this.curpage = 0;
      this.pagecount = 0;
      this.pagesize = 0;
      this.total = 0;

      this.getLogs();
    },

    changePage(val) {
      this.curpage = val - 1;
      this.list = [];
      this.getLogs();
    },
  },
};
</script>

<style src="../assets/css/creditlogs.css" scoped></style>
<style scoped>
.credit-page .price .p::before {
  content: var(--aog-currency);
  font-size: 70%;
}

.credit-page .price .status,
.pc-my-page .logs .status {
  font-size: 14px;
  font-weight: normal;
  color: green;
}

.el-select-dropdown__item.selected {
  color: var(--van-card-price-color);
}
</style>